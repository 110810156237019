/**
 * Imports
 */

import * as React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { maybeUserSelector } from 'features/user/redux/selectors';
import { styled } from 'theme';

import { PrivateRoute } from './PrivatRoute';
import { AccountPage, LoginPage } from 'features/user/pages';
import { Trans } from 'react-i18next';
import {
  // HomePage,
  UCCMonthlyReportsPage,
  MonthlyTravelWifiReportsPage,
  MonthlyUCCReportsPage,
  MonthlyGamblingReportsPage,
} from 'features/navigation/pages';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.Vibrant.Colors.Background.Primary};

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export type RoutesType =
  | 'monthly_reports'
  | 'ucc_monthly_reports'
  | 'monthly_travel_wifi_eur_reports'
  | 'monthly_ucc_nok_reports'
  | 'monthly_ucc_eur_reports'
  | 'monthly_ucc_gbp_reports'
  | 'monthly_gambling_gaus_gbp_reports'
  | 'monthly_gambling_gaus_eur_reports'
  | 'monthly_gambling_gaus_czk_reports'
  | 'monthly_gambling_novo_gbp_reports'
  | 'monthly_gambling_novo_eur_reports'
  | 'home'
  | 'account'
  | 'login';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  path: string;
  route: string;
  pageId: RoutesType;
};

export interface RootRouterProps {}

/**
 * Routes settings
 */
export const ROUTES: RouteLookup = {
  home: {
    pageName: <Trans i18nKey="Global.pages.home" />,
    pageId: 'monthly_gambling_gaus_gbp_reports',
    path: '/',
    route: '/',
  },
  monthly_reports: {
    pageName: <Trans i18nKey="Global.pages.monthly_reports" />,
    pageId: 'monthly_reports',
    path: '/monhtly_reports',
    route: '/monhtly_reports',
  },
  ucc_monthly_reports: {
    pageName: <Trans i18nKey="Global.pages.ucc_monthly_reports" />,
    pageId: 'ucc_monthly_reports',
    path: '/ucc_monhtly_reports',
    route: '/ucc_monhtly_reports',
  },
  monthly_ucc_nok_reports: {
    pageName: <Trans i18nKey="Global.pages.monthly_ucc_nok_reports" />,
    pageId: 'monthly_ucc_nok_reports',
    path: '/monhtly_ucc_nok_reports',
    route: '/monhtly_ucc_nok_reports',
  },
  monthly_ucc_eur_reports: {
    pageName: <Trans i18nKey="Global.pages.monthly_ucc_eur_reports" />,
    pageId: 'monthly_ucc_eur_reports',
    path: '/monhtly_ucc_eur_reports',
    route: '/monhtly_ucc_eur_reports',
  },
  monthly_ucc_gbp_reports: {
    pageName: <Trans i18nKey="Global.pages.monthly_ucc_gbp_reports" />,
    pageId: 'monthly_ucc_gbp_reports',
    path: '/monhtly_ucc_gbp_reports',
    route: '/monhtly_ucc_gbp_reports',
  },
  monthly_travel_wifi_eur_reports: {
    pageName: <Trans i18nKey="Global.pages.monthly_travel_wifi_eur_reports" />,
    pageId: 'monthly_travel_wifi_eur_reports',
    path: '/monthly_travel_wifi_eur_reports',
    route: '/monthly_travel_wifi_eur_reports',
  },
  monthly_gambling_gaus_gbp_reports: {
    pageName: (
      <Trans i18nKey="Global.pages.monthly_gambling_gaus_gbp_reports" />
    ),
    pageId: 'monthly_gambling_gaus_gbp_reports',
    path: '/monthly_gambling_gaus_gbp_reports',
    route: '/monthly_gambling_gaus_gbp_reports',
  },
  monthly_gambling_gaus_eur_reports: {
    pageName: (
      <Trans i18nKey="Global.pages.monthly_gambling_gaus_eur_reports" />
    ),
    pageId: 'monthly_gambling_gaus_eur_reports',
    path: '/monthly_gambling_gaus_eur_reports',
    route: '/monthly_gambling_gaus_eur_reports',
  },
  monthly_gambling_gaus_czk_reports: {
    pageName: (
      <Trans i18nKey="Global.pages.monthly_gambling_gaus_czk_reports" />
    ),
    pageId: 'monthly_gambling_gaus_czk_reports',
    path: '/monthly_gambling_gaus_czk_reports',
    route: '/monthly_gambling_gaus_czk_reports',
  },
  monthly_gambling_novo_gbp_reports: {
    pageName: (
      <Trans i18nKey="Global.pages.monthly_gambling_novo_gbp_reports" />
    ),
    pageId: 'monthly_gambling_novo_gbp_reports',
    path: '/monthly_gambling_novo_gbp_reports',
    route: '/monthly_gambling_novo_gbp_reports',
  },
  monthly_gambling_novo_eur_reports: {
    pageName: (
      <Trans i18nKey="Global.pages.monthly_gambling_novo_eur_reports" />
    ),
    pageId: 'monthly_gambling_novo_eur_reports',
    path: '/monthly_gambling_novo_eur_reports',
    route: '/monthly_gambling_novo_eur_reports',
  },
  account: {
    pageName: <Trans i18nKey="Global.pages.account" />,
    pageId: 'account',
    path: '/account',
    route: '/account',
  },
  login: {
    pageName: <Trans i18nKey="Global.pages.login" />,
    pageId: 'login',
    path: '/login',
    route: '/login',
  },
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  /* Redux*/
  const user = useSelector(maybeUserSelector);

  return (
    <BrowserRouter>
      {!user && (
        <Root>
          <Switch>
            <Route exact path={ROUTES.home.path}>
              <Redirect to={{ pathname: '/login' }} />
            </Route>
            <Route exact path={ROUTES.login.path}>
              <LoginPage />
            </Route>
          </Switch>
        </Root>
      )}
      {!!user && (
        <Root>
          <Switch>
            <PrivateRoute exact path={ROUTES.home.path}>
              <MonthlyGamblingReportsPage
                currency={'GBP'}
                contract={'Gauselmann'}
                contracts={['gauselmann', 'gauselmann_referal']}
              />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.ucc_monthly_reports.path}>
              <UCCMonthlyReportsPage currency={'NOK'} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.monthly_ucc_nok_reports.path}>
              <MonthlyUCCReportsPage currency={'NOK'} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.monthly_ucc_eur_reports.path}>
              <MonthlyUCCReportsPage currency={'EUR'} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={ROUTES.monthly_travel_wifi_eur_reports.path}
            >
              <MonthlyTravelWifiReportsPage currency={'EUR'} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.monthly_ucc_gbp_reports.path}>
              <MonthlyUCCReportsPage currency={'GBP'} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={ROUTES.monthly_gambling_gaus_gbp_reports.path}
            >
              <MonthlyGamblingReportsPage
                currency={'GBP'}
                contract={'Gauselmann'}
                contracts={['gauselmann', 'gauselmann_referal']}
              />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={ROUTES.monthly_gambling_gaus_eur_reports.path}
            >
              <MonthlyGamblingReportsPage
                currency={'EUR'}
                contract={'Gauselmann'}
                contracts={['gauselmann', 'gauselmann_referal']}
              />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={ROUTES.monthly_gambling_gaus_czk_reports.path}
            >
              <MonthlyGamblingReportsPage
                currency={'CZK'}
                contract={'Gauselmann'}
                contracts={['gauselmann']}
              />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={ROUTES.monthly_gambling_novo_gbp_reports.path}
            >
              <MonthlyGamblingReportsPage
                currency={'GBP'}
                contract={'Novomatic'}
                contracts={['novomatic', 'novomatic_referal']}
              />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={ROUTES.monthly_gambling_novo_eur_reports.path}
            >
              <MonthlyGamblingReportsPage
                currency={'EUR'}
                contract={'Novomatic'}
                contracts={['novomatic', 'novomatic_referal']}
              />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.account.path}>
              <AccountPage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.login.path}>
              <LoginPage />
            </PrivateRoute>
          </Switch>
        </Root>
      )}
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
