/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import { Logo, Text } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarCollapsedSelector } from 'features/navigation/redux/selectors';
import { Menu } from 'antd';
import {
  BarChartOutlined,
  TableOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'router';
import { Trans } from 'react-i18next';
import {
  collapseSidebar,
  openSidebar,
} from 'features/navigation/redux/actions';
import { firebaseLogout } from 'firebaseAPI';

/**
 * Styling
 */
const StyledMenu = styled(Menu)`
  height: 100%;
  max-width: 180px;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
`;
const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;
const ButtonMenuItem = styled(Menu.Item)`
  margin-top: auto !important;
  display: flex;
  align-items: center;
`;
const Top = styled(Menu.Item)`
  cursor: pointer;
  text-align: center;
  height: 48px;
  margin-bottom: 48px !important;
`;
const CollapseButton = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;
const LogoutButton = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export interface SidebarProps {}

/**
 * Sidebar component
 */
const Sidebar: React.FC<SidebarProps> = (_props) => {
  const [currentPath, setCurrentPath] = React.useState('string');
  const collapsed = useSelector(sidebarCollapsedSelector);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const toggleCollaped = () => {
    dispatch(collapsed ? openSidebar({}) : collapseSidebar({}));
  };

  const _navigateTo = (path: string) => {
    navigate.push(path);
  };

  /**
   *  Handle logout
   */
  const _handleLogout = async () => {
    await firebaseLogout();
    navigate.replace({ pathname: '/login' });
  };

  React.useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  return (
    <StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={[currentPath]}
    >
      <Top onClick={() => _navigateTo(ROUTES.home.path)}>
        <Logo size={collapsed ? 16 : 32} single={collapsed} />
      </Top>
      <MenuItem
        key={ROUTES.monthly_ucc_nok_reports.path}
        icon={<BarChartOutlined />}
        onClick={() => _navigateTo(`${ROUTES.monthly_ucc_nok_reports.path}`)}
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_ucc_nok_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_ucc_eur_reports.path}
        icon={<BarChartOutlined />}
        onClick={() => _navigateTo(`${ROUTES.monthly_ucc_eur_reports.path}`)}
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_ucc_eur_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_ucc_gbp_reports.path}
        icon={<BarChartOutlined />}
        onClick={() => _navigateTo(`${ROUTES.monthly_ucc_gbp_reports.path}`)}
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_ucc_gbp_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_travel_wifi_eur_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_travel_wifi_eur_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_travel_wifi_eur_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_gambling_gaus_gbp_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_gambling_gaus_gbp_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_gambling_gaus_gbp_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_gambling_gaus_eur_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_gambling_gaus_eur_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_gambling_gaus_eur_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_gambling_gaus_czk_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_gambling_gaus_czk_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_gambling_gaus_czk_reports" />
        </Text>
      </MenuItem>

      <MenuItem
        key={ROUTES.monthly_gambling_novo_gbp_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_gambling_novo_gbp_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_gambling_novo_gbp_reports" />
        </Text>
      </MenuItem>
      <MenuItem
        key={ROUTES.monthly_gambling_novo_eur_reports.path}
        icon={<BarChartOutlined />}
        onClick={() =>
          _navigateTo(`${ROUTES.monthly_gambling_novo_eur_reports.path}`)
        }
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.menu_items.monthly_gambling_novo_eur_reports" />
        </Text>
      </MenuItem>
      <LogoutButton onClick={_handleLogout} icon={<LogoutOutlined />}>
        <Text size="ui2">
          <Trans i18nKey="Global.actions.btn_logout" />
        </Text>
      </LogoutButton>
      <CollapseButton
        onClick={toggleCollaped}
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      >
        <Text size="ui2">
          <Trans i18nKey="Sidebar.actions.btn_collapse" />
        </Text>
      </CollapseButton>
    </StyledMenu>
  );
};

/**
 * Exports
 */
export { Sidebar };
